@font-face {
  font-family: 'helvetica-neue-regular';
  src: url("../fonts/helvetica-neue-regular.eot");
  src: url("../fonts/helvetica-neue-regular.eot?#iefix") format("embedded-opentype"),
  url("../fonts/helvetica-neue-regular.woff2") format("woff2"),
  url("../fonts/helvetica-neue-regular.woff") format("woff"),
  url("../fonts/helvetica-neue-regular.ttf") format("truetype");
}

@font-face {
  font-family: 'forma-djr';
  src: url("../fonts/FormaDJRUI.eot");
  src: url("../fonts/FormaDJRUI.eot?#iefix") format("embedded-opentype"),
  url("../fonts/FormaDJRUI.woff2") format("woff2"),
  url("../fonts/FormaDJRUI.woff") format("woff"),
  url("../fonts/FormaDJRUI.ttf") format("truetype");
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "forma-djr","HP Simplified","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
  font-size: 12pt;
}

html {
  font-size: 10px;
}

body {
  font-family: "forma-djr","HP Simplified","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
  overflow-x: hidden;
  font-size: 1.8rem;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  display: block;
}
 
select {
  color: black;
  border: 1px solid #949494;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  font-size: 12pt;
  height: 40px;
}

select:hover {
  color: black;
  border: 1px solid #949494;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  font-size: 12pt;
}

select option {
  color: #000;
  height: 40px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 12pt;
}

input[type="text"] {
  height: 40px!important;
  color: black;
  border: 1px solid #949494;
}

.invalid {
  border-color: red!important;
}

input[type="radio"] {
  outline: none!important;
  display: inline!important;
  cursor: pointer;
  position: relative;
  margin: 0px 5px 0px 0px;
  border: solid 1px #d8d8d8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1, .h1 {
  font-size: 3.5rem!important;
}

h2, .h2 {
  font-size: 3rem!important;
}

h5, .h5 {
  font-size: 2rem!important;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  min-width: 500px;
  max-width: 800px;
}

.title-container {
  padding-bottom: 50px;
}

.subtitle-container {
  padding-bottom: 20px;
}

.required-container {
  text-align: right;
  font-size: 12pt;;
}

.block-container {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.block-container-justify-center {
  justify-content: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.block-container-result-list {
  max-height: 200px;
  overflow-y: scroll;
}

.block-container-result-list ul {
  list-style-type: none;
  padding-left: 10px;
}

.block-container-result-list ul li {
  font-family: "forma-djr","HP Simplified","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
  margin-bottom: 15px;
  color: #007dba;
  text-decoration: none;
}

.block-container-result-list ul li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.input-container-long {
  width: 100%;
}

.input-container-long label {
  clear: both;
  display: block;
  padding-bottom: 5px;
  display: inline-block;
  line-height: 26px;
  font-weight: bold;
  font-size: 12pt;
}

.input-container label {
  clear: both;
  display: block;
  padding-bottom: 5px;
  display: inline-block;
  line-height: 26px;
  font-weight: bold;
  font-size: 12pt;
}

.button-container {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  padding-bottom: 11px;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.content_block {
  padding-bottom: 20px;
}

.secondary-button {
  display: table-cell;
  height: 40px!important;
  color: #FFF!important;
  background: #0096d6;
  border-style: none;
  padding-right: 15px;
  padding-left: 15px;
  width: 100px;
  font: bold 14pt "Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
}

.secondary-button:hover {
  cursor: pointer;
}

.safety-recall-container {
  padding: 15px 0px;
}

.spin-container {
  display: flex;
  height: 40px!important;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100px;
  color: #FFF!important;
  background: #0096d6;
}

.group-selects-container {
  display: flex;
}

.group-selects-container select {
  margin-right: 10px;
}

.group-selects-container .select-months {
  max-width: 140px;
  text-transform: uppercase;
}

.group-selects-container .select-numbers {
  max-width: 90px;
}

.checkbox-container {
  padding-left: 40px;
}

.checkbox-row {
  padding-left: 0px;
  display: flex;
}

.checkbox-row .checkradio label {
  max-width:60px;
  font-size: 12pt;
}

.checkbox-row label {
  width: 300px;
  font-size: 12pt;
  font-weight: bold;
}

.privacy-text {
  display: block;
  padding: 0 8px;
  margin-bottom: 10px;
  font-weight: bold;
  color: rgb(1,113,173);
}

.privacy-text:hover,
.privacy-text:visited {
  color: rgba(1,113,173,0.8);
}