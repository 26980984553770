.block-lines {
    display: flex;
    flex-direction: column!important;
    border: 1px solid #898888;
    padding: 20px 20px 20px 20px;
}

.block-detail-line {
    display: flex;
    flex-direction: row;
}

.block-detail-line label {
    clear: both;
    display: block;
    padding-bottom: 5px;
    display: inline-block;
    line-height: 26px;
    font-size: 16px;
    font-weight: bold;
    width: 200px;
}

.block-detail-line .col-detail {
    font-size: 16px;
}

.block-link {
    text-decoration: underline;
    text-align: center;
    color: #0096D6;
    cursor: pointer;
}